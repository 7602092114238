<template>
  <div class="container-fluid">
    <div
      class="col-12 row justify-content-between container-partners__search pr-0 pl-0 ml-0 mb-3"
    >
      <div
        class="col-xs-mobile col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-0"
      >
        <form class="d-flex">
          <i class="fas fa-search fa-flip-horizontal"></i>
          <input
            class="form-control inputFilter md-filter-input me-2"
            type="text"
            placeholder="Buscar por nombre"
            aria-label="Search"
            v-model="searchValue"
            @keyup="filterTable(getOrdersByInvoiceCode.orders)"
          />

          <a>
            <span
              :class="
                isFilter
                  ? 'clear-position clear-view material-icons close'
                  : 'clear-position clear-hide material-icons close'
              "
              @click="clearFilter"
              >close</span
            >
          </a>

          <div class="float-right ml-4">
            <DatePicker @changeDate="changeDates" />
          </div>
        </form>
      </div>
    </div>
    <div v-if="getOrdersByInvoiceCode">
      <card
        class="col-12 last-orders px-4 pt-4 table-container"
        type="white"
        header-classes="bg-transparent"
      >
        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center pb-3 justify-content-end">
              <p class="mr-2 mb-0 text-muted detail-labels">
                {{ getOrdersByInvoiceCode.total }} de
                {{ getOrdersByInvoiceCode.total }}
              </p>
              <a :href="handleExportOrders()" target="_blank">
                <button class="btn btn-outline-primary btn-maquillate">
                  Exportar
                </button>
              </a>
            </div>
          </div>
        </div>

        <TableIncome
          :content="'orders'"
          :items="filterTable(getOrdersByInvoiceCode.orders)"
        />
      </card>
    </div>
    <div v-else>
      <LoadingPanel />
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import { TableIncome } from '@/components/Admin/Dashboard';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import LoadingPanel from '@/components/LoadingPanel';
import axios from 'axios';
import config from '@/config/config';
import { INVOICE_GET_BY_CODE } from '@/store/actions/order';
import { USER_REQUEST } from '@/store/actions/user';
export default {
  data() {
    return {
      profile: null,
      code: this.$route.params.code,
      searchValue: null,
      isFilter: false,
      dateOnLoad: {
        startDate: moment().startOf('month').format(),
        endDate: moment().format(),
        ranged: 'last',
      },
      isLoading: false,
    };
  },
  watch: {
    searchValue: function () {
      if (this.searchValue == '' || this.searchValue == null) {
        this.isFilter = false;
      } else {
        this.isFilter = true;
      }
    },
  },
  components: {
    DatePicker,
    TableIncome,
    LoadingPanel,
  },
  mounted() {
    this.updateDateRange(this.dateOnLoad);
    this.$store
      .dispatch(USER_REQUEST)
      .then((resp) => {
        this.profile = this.getProfile.roles[0];
      })
      .catch((err) => {});
  },
  methods: {
    filterTable(array) {
      let table = null;

      if (this.searchValue) {
        table = array.filter((obj) => {
          if (
            obj.order_id.toLowerCase().includes(this.searchValue.toLowerCase())
          ) {
            return true;
          }
          if (
            obj.course.instructors[0].firstname
              .toLowerCase()
              .includes(this.searchValue.toLowerCase())
          ) {
            return true;
          }
          if (
            obj.course.name
              .toLowerCase()
              .includes(this.searchValue.toLowerCase())
          ) {
            return true;
          }
        });
      } else {
        table = array;
      }

      console.log(table);

      return table;
    },
    clearFilter() {
      this.searchValue = '';
    },
    changeDates(dates) {
      console.log(dates);
      this.dateOnLoad = dates;
      this.updateDateRange(dates);
    },
    async updateDateRange({ startDate, endDate, ranged }) {
      this.isLoading = true;
      const data = {
        // filter: `period=custom&startDate=${moment(
        // startDate
        // ).format()}&endDate=${moment(endDate).format()}&ranged=${ranged}`,
        code: this.code,
      };
      await this.$store
        .dispatch(INVOICE_GET_BY_CODE, data)
        .then((resp) => {
          console.log('data');
          console.log(this.getOrdersByInvoiceCode);
        })
        .catch((err) => {}); // Data table
      this.isLoading = false;
    },
    handleExportOrders() {
      const data = {
        // filter: `period=custom&startDate=${moment(this.dateOnLoad.startDate).format()}&endDate=${moment(this.dateOnLoad.endDate).format()}&ranged=${this.dateOnLoad.ranged}`,
        // type: this.typeGet,
        code: this.code,
        // user_id: this.getProfile.id
      };
      return config.data.apiUrl + `/admin/invoices/${data.code}/print/pdf`;
    },
  },
  computed: {
    ...mapGetters(['getOrdersByInvoiceCode', 'getProfile']),
  },
};
</script>

<style>
.reportrange-text {
  padding: 0.72rem 10px !important;
}
</style>
