<template>
  <div>
    <el-table
      class="table-responsive table-flush order-last-list"
      header-row-class-name="thead-light"
      :data="items"
    >
      <el-table-column label="Nombre" prop="code" min-width="70px" sortable>
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body">
              <span class="font-weight-500 name mb-0 text-sm">
                <div class="customer-info">
                  <span class="mb-0">{{ row.name }}</span>
                </div>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Email" prop="code" min-width="70px" sortable>
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body">
              <span class="font-weight-500 name mb-0 text-sm">
                <div class="customer-info">
                  <span class="mb-0">{{ row.email }}</span>
                </div>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Fecha" prop="enable" sortable>
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body order-date">
              <span class="font-weight-500 name mb-0">
                {{ getDateFormat(row.created_at) }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import moment from 'moment-timezone';

export default {
  name: 'TableUsers',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    items: Array,
  },
  methods: {
    getDateFormat(date) {
      // return moment.tz(date, "America/New_York").fromNow();
      return moment(date).format('ll');
    },
  },
};
</script>

<style>
.order-last-list .customer-info span {
  font-size: 14px;
  color: #000;
  font-weight: 300;
}
</style>
