<template>
  <div>
    <el-table
      :data="items"
      class="table-responsive table-flush order-last-list mb-4"
      header-row-class-name="thead-light"
    >
      <el-table-column
        v-if="content"
        label="Codigo de Orden"
        min-width="120px"
        prop="code"
        sortable
      >
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body">
              <span class="name mb-0 text-sm">
                <div class="customer-info" role="button">
                  <h4 class="mb-0">
                    <router-link
                      :to="'/admin/ordenes/' + row.order_id"
                      class="p-0 text-decoration-none colorPrimary"
                    >
                      {{ row.order_id }}
                    </router-link>
                  </h4>
                </div>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Codigo de Invoice"
        min-width="120px"
        prop="code"
        sortable
      >
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body">
              <span class="name mb-0 text-sm">
                <div class="customer-info" role="button">
                  <h4 class="mb-0">
                    <router-link
                      v-if="content"
                      :to="'/admin/invoices/invoice/detail/' + row.invoice_id"
                      class="p-0 text-decoration-none colorPrimary"
                    >
                      {{ row.invoice_id }}
                    </router-link>
                    <router-link
                      v-else
                      :to="'invoices/invoice/' + row.NumFacturaMaq"
                      class="p-0 text-decoration-none colorPrimary"
                    >
                      {{ row.NumFacturaMaq }}
                    </router-link>
                  </h4>
                </div>
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Monto"
        min-width="60px"
        prop="expiration"
        sortable
      >
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body order-amount">
              <span class="font-weight-500 name mb-0 text-sm">
                ${{ row.total }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="content"
        label="Curso"
        min-width="90px"
        prop="enable"
        sortable
      >
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body order-date">
              <span class="font-weight-500 name mb-0 text-sm text-capitalize">
                {{ row.course.name }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-else
        label="Cursos"
        min-width="90px"
        prop="enable"
        sortable
      >
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body order-date">
              <span class="font-weight-500 name mb-0 text-sm text-capitalize">
                {{ row.cant }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Usuario" min-width="90px" prop="enable" sortable>
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body order-date">
              <span class="font-weight-500 name mb-0 text-sm">
                {{ row.user.name }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Email" min-width="100px" prop="enable" sortable>
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body order-date">
              <span class="font-weight-500 name mb-0 text-sm">
                {{ row.user.email }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        v-if="content"
        label="Instructor"
        min-width="80px"
        prop="enable"
        sortable
      >
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body order-date">
              <span class="font-weight-500 name mb-0 text-sm">
                {{
                  row.course.instructor ? row.course.instructor : 'Instructor'
                }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Fecha" min-width="80px" prop="enable" sortable>
        <template #default="{ row }">
          <div class="media align-items-center">
            <div class="media-body order-date">
              <span class="font-weight-500 name mb-0 text-sm">
                {{ getDateFormat(row.created_at) }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import moment from 'moment-timezone';

export default {
  name: 'TableIncome',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    content: {
      type: String,
      default: '',
    },
  },
  methods: {
    getDateFormat(date) {
      // return moment.tz(date, "America/New_York").fromNow();
      return moment(date).format('ll');
    },
  },
};
</script>

<style>
.colorPrimary {
  color: #b7217e !important;
}
</style>
